import * as React from "react";
import * as PropTypes from "prop-types";
import * as classnames from "classnames";
import * as theme from "styles/_baseForm.scss";
import * as appTheme from "./theme.scss";
import {observer} from "mobx-react";
import {
	FormInput,
	Label,
	AggregateChangeComponent,
	Checkbox,
	FormChips,
	Tooltip,
	PageMessage,
	Button, HLine, Modal, ConfirmPopMessage
} from "@vidazoo/ui";
import {CodeEditor, FormMode, normalizeEventValue, tryParseJSON, tryStringifyJSON} from "@vidazoo/ui-framework";
import EditApplicationStore from "../../stores/EditApplicationStore";
import CreateApplicationStore from "../../stores/CreateApplicationStore";
import * as moment from "moment";

export interface IDetailsFormProps {
	mode: FormMode;
	store: EditApplicationStore | CreateApplicationStore;
}

export interface IDetailsFormState {
	showRestoreMessage: boolean;
	version: any;
}

@observer
export default class HistoryForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

	public static defaultProps: Partial<IDetailsFormProps> = {};

	constructor(props: IDetailsFormProps, context) {
		super(props, context);

		this.state = {
			showRestoreMessage: false,
			version: null
		};
	}

	private copyText = (e) => {
		const {store} = this.props;
		e.target.select();
		document.execCommand("Copy");
		store.onCopySuccess();
	}

	private hideRestoreMessage = () => {
		this.setState({
			showRestoreMessage: false
		});
	}

	private showRestoreMessage = () => {
		this.setState({
			showRestoreMessage: true
		});
	}

	private restoreHandler = (version) => {
		this.setState({
			showRestoreMessage: true,
			version
		});
	}

	private restore = () => {
		const {store} = this.props;
		this.hideRestoreMessage();

		if (!(store instanceof CreateApplicationStore)) {
			store.restore(this.state.version.date);
		}
	}

	private renderRestoreMessage(): JSX.Element {
		const {showRestoreMessage, version} = this.state;
		const {store} = this.props;

		return (
			<Modal external exitOnClickOutside onClose={this.hideRestoreMessage} isOpen={showRestoreMessage}>
				<ConfirmPopMessage
					yellow
					title="Restore App?"
					text={<span>Are you sure you want to restore <Label weight={600} block={false}>
						{store.item.name}</Label> to the previous version created on <Label weight={600} block={false}>
						{moment(version.date).format("LLL")}</Label>?
					</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Restore"
					onCancelClick={this.hideRestoreMessage}
					onConfirmClick={this.restore}
				/>
			</Modal>
		);
	}

	private renderConfigsEditor(version): JSX.Element {

		let value = version.env || {};

		value = tryStringifyJSON(value, null, 4) || {};

		return (
			<FormInput className={appTheme.input} value={value} rows={7} readOnly multiline fill
						style={{fontFamily: "monospace", fontSize: "12px"}}/>
		);
	}

	private renderHistoryList = () => {
		const {item} = this.props.store;
		const {history} = item;

		return history.map((version, index) => {
			return (
				<React.Fragment key={index}>
					<div className={appTheme.version} key={index}>
						<div className={appTheme.restore}>
							<Label className={appTheme.date} monserrat uppercase
									weight={600}>{moment(version.date).format("LLL")}</Label>
							{index === 0 ?
								<Button small blue disabled uppercase iconName="icon-network">Current</Button>
								:
								<Button onClick={() => this.restoreHandler(version)} small orange uppercase
										iconName="icon-reload">Restore</Button>
							}
						</div>
						{this.renderConfigsEditor(version)}
					</div>
					<HLine/>
				</React.Fragment>
			)
		})
	}

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="There are no previous versions available"
					description="We are sorry for the inconvenience."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	public render(): JSX.Element {

		const {store, mode} = this.props;
		const {item} = store;
		const {history} = item;
		const {showRestoreMessage} = this.state;

		return (
			<div className={theme.form}>
				<div className={theme.header}>
					<Label size={13} weight={700} spacing={1.25} monserrat>Restore previous versions</Label>
				</div>
				{this.renderHistoryList()}
				{!history || history.length < 1 ? this.renderError() : null}
				{showRestoreMessage ? this.renderRestoreMessage() : null}
			</div>
		);
	}
}
