import * as React from "react";
import { observer } from "mobx-react";
import * as PropTypes from "prop-types";
import { IDictionary } from "@vidazoo/ui-framework";
import { SortDirection, Table, TableBody, TableHeaderToggle, TableHeaderColumn, Density, Surface, NativeScrollable } from "@vidazoo/ui";
import { MultiAccountHeader } from "common/components";
import IApplication from "../../interfaces/IApplication";
import ApplicationRow from "./ApplicationRow";

export interface IExamplesListProps {
	items?: IApplication[];

	searchQueries: IDictionary<string>;

	sortBy: string;

	sortDirection: SortDirection;

	onSort: (sortBy: string) => void;

	onActiveChange: (item: IApplication) => void;

	onSearch: (searchKey: string, value: string) => void;

	onDelete: (item: IApplication) => void;

	onEdit: (item: IApplication) => void;

	density: Density;

	loadMore?: () => void;

	toggleItem: (id: string) => void;

	toggleAllItems: () => void;

	allItemsChecked: boolean;

	selectedValues: string[];
}

export default observer(({ loadMore, items, sortBy, sortDirection, allItemsChecked, toggleAllItems, onSort, selectedValues, toggleItem, searchQueries, onSearch, onActiveChange, onDelete, onEdit, density }: IExamplesListProps): JSX.Element => (
	<Surface level={1}>
		<NativeScrollable scrollSize={6} suppressY stickyOverflowScrollX bold>
			<Table loadMoreItems={loadMore} stickyHeaderOffsetTop={68} enableStickyHeader={true} fill density={density}>
				<TableBody itemHeight={47}>
					{items.map((item) => <ApplicationRow onToggle={toggleItem} isSelected={selectedValues.indexOf(item._id) > -1} key={item._id} item={item} sortBy={sortBy} onActiveChange={onActiveChange} onDelete={onDelete} onEdit={onEdit} />)}
				</TableBody>
				<thead>
				<tr>
					<TableHeaderToggle checked={allItemsChecked} onChange={toggleAllItems} />
					<TableHeaderColumn
						blue
						label="Name"
						sortable={true}
						sortBy="name"
						onSort={onSort}
						sortActive={sortBy === "name"}
						sortDirection={sortDirection}
						searchKey="name"
						searchable={true}
						onSearch={onSearch}
						searchQuery={searchQueries["name"]}
					/>
					<TableHeaderColumn
						blue
						label="App Id"
						sortable={true}
						sortBy="id"
						onSort={onSort}
						sortActive={sortBy === "id"}
						sortDirection={sortDirection}
						searchKey="id"
						searchable={true}
						onSearch={onSearch}
						searchQuery={searchQueries["id"]}
					/>
					<MultiAccountHeader
						sortBy={sortBy}
						onSort={onSort}
						sortDirection={sortDirection}
						onSearch={onSearch}
						searchQueries={searchQueries} />
					{/*<TableHeaderColumn*/}
					{/*	blue*/}
					{/*	label="Date"*/}
					{/*	sortable={true}*/}
					{/*	sortBy="created"*/}
					{/*	onSort={onSort}*/}
					{/*	sortActive={sortBy === "created"}*/}
					{/*	sortDirection={sortDirection}*/}
					{/*	searchable={false}*/}
					{/*/>*/}
					{/*<TableHeaderColumn*/}
					{/*	blue*/}
					{/*	label="Updated"*/}
					{/*	sortable={true}*/}
					{/*	sortBy="updated"*/}
					{/*	onSort={onSort}*/}
					{/*	sortActive={sortBy === "updated"}*/}
					{/*	sortDirection={sortDirection}*/}
					{/*	searchable={false}*/}
					{/*/>*/}
					{/*<TableHeaderColumn*/}
					{/*	blue*/}
					{/*	label="Active"*/}
					{/*	sortable={true}*/}
					{/*	sortBy="isActive"*/}
					{/*	onSort={onSort}*/}
					{/*	sortActive={sortBy === "isActive"}*/}
					{/*	sortDirection={sortDirection}*/}
					{/*/>*/}
					<TableHeaderColumn
						label="Actions"
					/>
				</tr>
				</thead>
			</Table>
		</NativeScrollable>
	</Surface>
));
