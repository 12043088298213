import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {observer} from "mobx-react";
import {
	FormInput,
	AggregateChangeComponent,
	Tooltip,
	FormChips,
} from "@vidazoo/ui";
import {CodeEditor, FormMode, normalizeEventValue, tryParseJSON, tryStringifyJSON} from "@vidazoo/ui-framework";
import EditApplicationStore from "../../stores/EditApplicationStore";
import CreateApplicationStore from "../../stores/CreateApplicationStore";

export interface IDetailsFormProps {

	mode: FormMode;

	store: EditApplicationStore | CreateApplicationStore;

}

export interface IDetailsFormState {

}

@observer
export default class DetailsForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

	public static defaultProps: Partial<IDetailsFormProps> = {};

	constructor(props: IDetailsFormProps, context) {
		super(props, context);
	}

	private onChangeDetail = (e) => {
		const {store} = this.props;

		const value = normalizeEventValue(e);

		store.updateParam(e.target.name, value);
	}

	private renderConfigsEditor(): JSX.Element {

		const {store} = this.props;

		const {item} = store;

		let value = item.env || {};

		value = tryStringifyJSON(value, null, 4) || {};

		return (
			<CodeEditor
				key={item.env}
				style={{width: "100%"}}
				label="Configs"
				value={value}
				onChange={this.onConfigsChanged}
			/>
		);
	}

	private onConfigsChanged = ({value}) => {
		const {store} = this.props;

		value = tryParseJSON(value);

		if (value !== null) {
			store.updateParam("env", value);
		}
	}

	private copyText = (e) => {
		const {store} = this.props;
		e.target.select();
		document.execCommand("Copy");
		store.onCopySuccess();
	}

	private onAddApplication = (value: string) => {
		const {addInheritApplication} = this.props.store;
		addInheritApplication(value);
	}

	private onRemoveApplication = (value: string) => {
		const {removeInheritApplication} = this.props.store;
		removeInheritApplication(value);
	};

	public render(): JSX.Element {

		const {store, mode} = this.props;

		const {item, applications, isLoadingApplications} = store;

		return (
			<div className={theme.form}>
				<AggregateChangeComponent onChange={this.onChangeDetail}>
					{(mode === FormMode.Edit) ?
						<div className={theme.row}>
							<FormInput value={item.id} className={theme.input} name="id" label="App Id"
										readOnly fill/>
						</div>
						: null}
					<div className={theme.row}>
						<FormInput value={item.name} className={theme.input} name="name" label="Name"
									required fill/>
					</div>
					<div className={theme.row}>
						<FormChips name="parentApplications" emptyPlaceholder="Add Application" allowCreate={false} maxItems={5}
								   onSelect={this.onAddApplication} isLoading={isLoadingApplications}
								   onDelete={this.onRemoveApplication} chips={item.parentApplications} label="Parent Applications" fill
								   itemLabelKey="name" itemValueKey="id" dataSource={applications} />
					</div>
					<div className={theme.row}>
						{this.renderConfigsEditor()}
					</div>
					{(mode === FormMode.Edit) ?
						<div className={theme.row}>
							<Tooltip title="double click to copy text" position="bottom">
								<FormInput value={item.publicKey} className={theme.input} name="publicKey"
											label="App Public Key"
											rows={7} readOnly multiline fill onDoubleClick={this.copyText}/>
							</Tooltip>
						</div>
						: null}
				</AggregateChangeComponent>
			</div>
		);
	}
}
