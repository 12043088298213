import * as React from "react";
import * as theme from "./theme.scss";
import * as themeBaseList from "styles/_baseList.scss";
import * as _ from "lodash";
import {PageContainer, Modal, SearchBar, Surface, Spacer, Loader, PageMessage} from "@vidazoo/ui";
import DashboardStore from "../stores/DashboardStore";
import DashboardItem from "./DashboardItem";
import IApplication from "../../applications/interfaces/IApplication";
import {observer} from "mobx-react";

export interface IDashboardProps {
	isLoadingData: boolean;
}

export interface IDashboardState {
	itemInfo?: IApplication;
}

@observer
export default class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

	public static defaultProps: Partial<IDashboardProps> = {};

	private store: DashboardStore;

	constructor(props, context) {
		super(props, context);

		this.store = new DashboardStore();
	}

	public componentWillMount() {
		this.store.startInterval();
	}

	public componentWillUnmount() {
		this.store.stopInterval();
	}

	private onShowInfo = (item: IApplication) => {
		this.setState({itemInfo: item});
	}

	private renderItemChart = (item: IApplication) => {
		const {onCopySuccess} = this.store;
		return (

			<DashboardItem onShowInfo={this.onShowInfo} key={item._id} item={item} onCopySuccess={onCopySuccess}/>
		);
	}

	private onSearch = (e) => {
		this.store.setSearch(e.target.value);
	}

	private onSearchClear = () => {
		this.store.clearSearch();
	}

	private renderLoader(): JSX.Element {
		return (
			<div className={themeBaseList.message}>
				<Loader size={1} gray className={themeBaseList.loader} />
				<PageMessage
					small
					title="Loading Dashboard"
					description="Please wait..."
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		const {applications, search, isLoadingData} = this.store;

		return (
			<PageContainer pageTitle="Dashboard" className={theme.container}>
				<div className={theme.dashboard}>
					<SearchBar inner placeholder="search" value={search} onChange={this.onSearch}
							   onClear={this.onSearchClear} disabled={isLoadingData} className={theme.searchBar}/>
					<Spacer bottom={20}/>
					<div className={theme.grid}>
						{applications.map(this.renderItemChart)}
					</div>

					{isLoadingData ? this.renderLoader() : null}
				</div>
			</PageContainer>
		);
	}
}
