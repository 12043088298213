import * as React from "react";
import * as theme from "./themeBar.scss";
import IApplication from "../../applications/interfaces/IApplication";
import {FormInput, Label, Modal, Tooltip} from "@vidazoo/ui";

export interface IIconsBarProps {
	item: IApplication;
	onShowInfo: (item: IApplication) => void;
	onCopySuccess: () => void;
}

export interface IIconsBarState {
	showAppModal: boolean;
	app: any;
}

const ICONS_AVAILABLE_LIST = ["redis", "aws", "elastic", "amqp", "highwinds", "memSql", "memSql6", "memSqlConn", "postgres", "snowflake", "mongodb", "sentry", "heroku", "aiven", "bigQuery", "digitalOcean", "kafka", "kafkaConn", "facebook", "gmailUser", "gmail", "googleSheets", "neo4j"]
const baseUrl = "https://static.vidazoo.com/vidazoo/assets/vault";

export default class IconsBar extends React.Component<IIconsBarProps, IIconsBarState> {
	public static defaultProps: Partial<IIconsBarProps> = {};

	constructor(props, context) {
		super(props, context);

		this.state = {
			showAppModal: false,
			app: null
		};
	}

	private openModalHandler = (app) => {
		this.onShowAppModal()
		this.setState({app})
	}

	private onShowAppModal = () => {
		this.setState({showAppModal: true})
	}

	private onHideAppModal = () => {
		this.setState({showAppModal: false, app: null})
	}

	private copyText = (e) => {
		e.target.select();
		document.execCommand("Copy");
		this.props.onCopySuccess();
	}

	private renderModal = () => {
		const {item} = this.props;
		const {showAppModal, app} = this.state;

		return (
			<Modal className={theme.modal} exitOnClickOutside isOpen={showAppModal} onClose={this.onHideAppModal}
					title="Quick look" panel windowStyle={{width: 600}}>
				<div className={theme.head}>
					<img src={`${baseUrl}/${this.selectIcon(app.name)}.png`} alt={app.name}/>
					<div className={theme.labels}>
						<Label monserrat uppercase weight={600}>{item.name}</Label>
						<Label monserrat uppercase weight={400}>{app.name}</Label>
					</div>
				</div>
				<div className={theme.env}>
					<Tooltip title="double click to copy text" position="bottom">
						<FormInput value={app.env} autoFocus name="env"
									rows={7} readOnly multiline fill onDoubleClick={this.copyText}/>
					</Tooltip>
				</div>
			</Modal>
		)
	}

	private filterSystems = () => {
		const {item} = this.props
		const {filteredEnv} = item;
		let apps = []

		for (const key in filteredEnv) {
			if (filteredEnv.hasOwnProperty(key)) {
				if (ICONS_AVAILABLE_LIST.includes(key)) {
					const obj = {};
					obj["name"] = `${key}`;
					obj["env"] = JSON.stringify(filteredEnv[`${key}`]);
					apps.push(obj)
				}
			}
		}

		if (apps.length > 9) {
			apps = apps.slice(0, 9)
		}

		return apps.map((app) => {
			return (
					<img
						src={`${baseUrl}/${this.selectIcon(app.name)}.png`}
						className={theme.icon}
						onClick={() => this.openModalHandler(app)}
						alt={app.name}
						key={app.name}
					/>
			)
		});
	}

	private selectIcon = (name) => {
		switch (name) {
			case "memSql6":
				return "memSql";
			case "memSqlConn":
				return "memSql";
			case "kafkaConn":
				return "kafka";
			case "gmailUser":
				return "gmail";
			default:
				return name;
		}
	}

	public render(): JSX.Element {
		const {item} = this.props;

		return (
			<div className="align-center">
				<div className={theme.bar}>
					{this.filterSystems()}
				</div>
				{this.state.showAppModal ? this.renderModal() : null}
			</div>
		);
	}
}
