import * as React from "react";
import * as PropTypes from "prop-types";
import { History } from "history";
import { Router, Switch } from "react-router-dom";
import App from "modules/app/containers/app";
import { AuthRoute } from "common/components";
import { PageMessage } from "@vidazoo/ui";
import { SpreadPageLoader, ScrollToTop, PendingNewVersion } from "@vidazoo/ui-framework";
import { appUrlsService } from "common/services";

export interface IRootProps {
	isAuthenticating?: boolean;

	isAuthError?: boolean;

	isAuthTimeout?: boolean;

	history?: History;

	pendingNewVersion?: boolean;

	setPendingNewVersionState?: (state: boolean) => void;
}

export interface IRootState {

}

export default class Root extends React.Component<IRootProps, IRootState> {

	public static defaultProps: Partial<IRootProps> = {

	};

	constructor(props, context) {
		super(props, context);
	}

	private onDismissPendingNewVersion = () => {
		const { setPendingNewVersionState } = this.props;

		setPendingNewVersionState(false);
	}

	private onConfirmPendingNewVersion = () => {

		this.onDismissPendingNewVersion();

		window.location.reload();
	}

	private getErrorProps(): { description: string; iconName: string; } {
		const { isAuthTimeout } = this.props;

		let description, iconName;

		if (isAuthTimeout) {
			description = "Pleasse check your network and try again.";
			iconName = "icon-network";
		} else {
			description = "We had an error loading your data. Please try again.";
			iconName = "icon-vidazoo-triangle";
		}

		return { description, iconName };
	}

	public render(): JSX.Element {
		const { isAuthenticating, isAuthError, history, pendingNewVersion } = this.props;

		if (isAuthError) {
			return (
				<div className="flex flex-1 align-center justify-center">
					<PageMessage
						small
						title="Oops! something went wrong."
						{...this.getErrorProps()}
					/>
				</div>
			);
		}

		if (isAuthenticating) {
			return (
				<SpreadPageLoader blue />
			);
		}

		return (
			<React.Fragment>
				<PendingNewVersion onDismiss={this.onDismissPendingNewVersion} onConfirm={this.onConfirmPendingNewVersion} pendingNewVersion={pendingNewVersion} />
				<Router history={history}>
					<React.Fragment>
						<ScrollToTop />
						<Switch>
							<AuthRoute path={appUrlsService.app()} component={App} />
						</Switch>
					</React.Fragment>
				</Router>
			</React.Fragment>
		);
	}
}
