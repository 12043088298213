import {action, observable, runInAction, toJS} from "mobx";
import * as _ from "lodash";
import IApplication from "../../applications/interfaces/IApplication";
import applicationsAPI from "../../applications/api/applicationsAPI";
import {notificationsStore} from "common/stores";

const APPS_FIELDS = {
	name: 1,
	data: 1,
};

export default class DashboardStore {
	private timer: any;
	@observable public isLoadingData: boolean = false;
	@observable public search: string = "";
	@observable public applications: IApplication[] = [];
	@observable public unFilteredApps: IApplication[] = [];

	@action
	public setApps(applications: IApplication[]) {
		this.unFilteredApps = applications;
		this._filterApplication();
	}

	@action
	private _filterApplication() {
		this.applications = this._filter(this.search);
	}

	private _filter(search) {
		if (search) {
			return this.unFilteredApps.filter((app) => Object.keys(app).some((property) => property !== "filteredEnv" ? app[property].toLowerCase().includes(search.toLowerCase()) : null));
		} else {
			return this.unFilteredApps;
		}
	}

	@action
	public setSearch(str: string) {
		this.search = str;
		this._filterTaskDebounce();
	}

	@action
	public clearSearch() {
		this.search = "";
		this._filterApplication();
	}

	public _filterTaskDebounce = _.debounce(() => {
		this._filterApplication();
	}, 250);

	@action
	private async _getItems() {
		this.isLoadingData = true;

		const res = await applicationsAPI.getAllDashboard({fields: APPS_FIELDS});

		runInAction(() => {
			this.isLoadingData = false;
			this.setApps(res.data.results);
		});
	}

	@action
	public startInterval() {
		this._getItems();
		// this.timer = setInterval(() => this._getItems(), 10000);
	}

	@action
	public stopInterval() {
		clearTimeout(this.timer);
	}

	@action
	public onCopySuccess() {
		notificationsStore.pushSuccessNotification({
			title: "Copy Complete",
			text: "Text copied successfully",
			timeout: 5000
		});
	}
}
