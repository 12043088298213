import { observable, action, computed } from "mobx";
import * as _ from "lodash";
import IUser from "common/interfaces/IUser";
import INetwork from "common/interfaces/INetwork";
import { gravatar } from "@vidazoo/ui-framework";

export default class BaseUserStore implements IUser {
	public _id: string;
	public date: number;
	public created: number;
	public updated: number;
	public isDeleted: boolean;
	public avatar: string;
	@observable public username: string;
	@observable public network: INetwork;
	@observable public roles: string[];
	@observable public firstName: string;
	@observable public lastName: string;
	@observable public email: string;
	@observable public isActive: boolean;
	@observable public credentials: any;
	@observable public children: any[];

	@action public initialize(user: IUser) {
		this._id = user._id;
		this.date = user.date;
		this.created = user.created;
		this.updated = user.updated;
		this.username = user.username;
		this.network = user.network;
		this.roles = user.roles;
		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.email = user.email;
		this.isActive = user.isActive;
		this.credentials = user.credentials;
		this.avatar = gravatar.getUrl(this.email);
	}

	@action public reset() {
		this._id = null;
		this.date = null;
		this.username = "";
		this.network = null;
		this.roles = ["user"];
		this.firstName = "";
		this.lastName = "";
		this.email = "";
		this.isActive = true;
		this.credentials = [];
		this.children = [];
	}

	@action public updateParam(key: string, value: any) {
		if (this[key] !== undefined) {
			this[key] = value;
		}
	}

	@action public updateParams(partial: Partial<IUser>) {
		_.forEach(partial, (value: string, key: string) => {
			this.updateParam(key, value);
		});
	}

	@computed get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	public toJSON() {
		return {
			id: this._id,
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			roles: this.roles
		};
	}
}
