// System
import { BaseNotificationsStore, ProfileStore } from "@vidazoo/ui-framework";
import InitialState from "./InitialState";
import NavigationStore from "./NavigationStore";
import SessionStore from "./SessionStore";
import CurrentUserStore from "./CurrentUserStore";
import UIStore from "./UIStore";

export const initialState = new InitialState();
export const uiStore = new UIStore();
export const navigationStore = new NavigationStore();
export const notificationsStore = new BaseNotificationsStore();
export const sessionStore = new SessionStore();
export const currentUserStore = new CurrentUserStore();
export const profileStore = new ProfileStore(notificationsStore);
