import App from "./App";
import { inject, observer } from "mobx-react";
import {gravatar} from "@vidazoo/ui-framework";

export default inject((stores: any, props: any, context: any): any => ({
	userName: stores.sessionStore.user.firstName,
	userEmail: stores.sessionStore.user.email,
	userAvatarUrl: gravatar.getUrl(stores.sessionStore.user.email),
	onSignOut: stores.sessionStore.logout,
	notificationsStore: stores.notificationsStore,
	uiStore: stores.uiStore,
	userScopes: stores.sessionStore.userScopes
}))(observer(App));
