import {action, transaction} from "mobx";
import { navigationStore } from "common/stores";
import { appUrlsService } from "common/services";
import BaseApplicationStore from "./BaseApplicationStore";
import applicationsAPI from "../api/applicationsAPI";
import {tryStringifyJSON} from "@vidazoo/ui-framework";

export default class EditApplicationStore extends BaseApplicationStore {

	@action public getItem(id: string) {
		this.isLoading = true;
		this.loadError = false;

		applicationsAPI.getOne(id)
			.then((item) => this._setItem(item.data))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public submit() {
		applicationsAPI.update(this.item.id, this.item)
			.then((item) => this._onSubmitSuccess(item.data))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public delete() {
		applicationsAPI.delete(this.item.id)
			.then(() => this.onSubmitSuccess())
			.then(() => navigationStore.push(appUrlsService.applications()))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public generateKey() {
		applicationsAPI.generateKey(this.item.id)
			.then((res) => {
				this.setKeys(res.data);
				this.onGenerateSuccess();
			})
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public restore(version) {
		applicationsAPI.restore(this.item.id, version)
			.then(() => this.onSubmitSuccess())
			.then(() => navigationStore.push(appUrlsService.applications()))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action private _onSubmitSuccess = (item) => {
		this._setInheritEnv(item.inheritEnv);
		this.onSubmitSuccess();
	}

	@action private _setItem = (item) => {
		this._setInheritEnv(item.inheritEnv);
		this.setItem(item);
	}

	@action private _setInheritEnv = (value) => {
		transaction(() => {
			this.inheritEnv = tryStringifyJSON(value, null, 4);
		})
	}
}
