import { LocationState, History, createBrowserHistory } from "history";

export default class NavigationStore {
	private history: History;

	constructor() {
		this.history = createBrowserHistory();
	}

	public push(state: LocationState | string) {
		this.history.push(state);
	}
}
