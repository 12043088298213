class AppUrlsService {

	public app(): string {
		return `/`;
	}

	public dashboard(): string {
		return `/dashboard`;
	}

	public applications(): string {
		return `/applications`;
	}

	public application(): string {
		return `/application`;
	}

	public createApplication(): string {
		return `${this.application()}/create`;
	}

	public editApplication(id: string = ":id"): string {
		return `${this.application()}/${id}/edit`;
	}

}

export default new AppUrlsService();
