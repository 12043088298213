import {observable, action, transaction} from "mobx";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {notificationsStore} from "common/stores";
import IApplication from "../interfaces/IApplication";
import applicationsAPI from "../api/applicationsAPI";

export default abstract class BaseApplicationStore extends BaseItemStore<IApplication> {
	@observable public privateKey: string;
	@observable public appId: string;
	@observable public isModalOpened: boolean;
	@observable public applications: IApplication[];
	@observable public isLoadingApplications: boolean;
	@observable public inheritEnv: any;

	constructor(fromDuplicate?: IApplication) {
		super(notificationsStore, "Application");

		this.reset();

		if (fromDuplicate) {
			this.setItem(fromDuplicate);
		}
	}

	@action
	public reset() {
		transaction(() => {
			super.reset();

			this.item = {
				...this.item,
				name: "",
				parentApplications: observable.array([]),
				description: "",
				env: { all: {}, development: {}, production: {}, testing: {} }
			};

			this.privateKey = "";
			this.appId = ""
			this.isModalOpened = false;
			this.inheritEnv = observable.object({});
		});

		this.isLoadingApplications = false;
		this.applications = observable.array([]);
	}

	@action public onCopySuccess() {
		notificationsStore.pushSuccessNotification({
			title: "Copy Complete",
			text: "Text copied successfully",
			timeout: 5000
		});
	}

	@action public onGenerateSuccess() {
		notificationsStore.pushSuccessNotification({
			title: "Generate keys",
			text: "Keys generated successfully",
			timeout: 5000
		});
	}

	@action public setKeys = (data) => {
		transaction(() => {
			this.privateKey = data.privateKey;
			this.appId = data.id;
			this.isModalOpened = true;
		});
	};

	@action public onHideModal = () => {
		transaction(() => {
			this.isModalOpened = false;
		});
	};

	@action public getApplications = () => {
		this.isLoadingApplications = true;
		applicationsAPI.getAll({fields: {name: 1}, filter: {isDeleted: false}})
			.then((res) => this._setApplications(res))
			.catch(() => this.onGetApplicationError());
	}

	@action private _setApplications = (res) => {
		this.applications = res.data.results;
		this.applications = this.applications.filter((app) => app.id !== this.item.id);
		this.isLoadingApplications = false;
	}

	@action public onGetApplicationError() {
		this.isLoadingApplications = false;
		notificationsStore.pushErrorNotification({
			title: "Operation Faile",
			text: "Failed to get applications",
			timeout: 3000
		});
	}

	@action public addInheritApplication = (value: string) => {
		if (!this.item.parentApplications) {
			this.item.parentApplications = [];
		}
		this.item.parentApplications = [...this.item.parentApplications, value];
	};

	@action public removeInheritApplication = (value: string) => {
		this.item.parentApplications = this.item.parentApplications.filter((app) => app !== value);
	};
}
