import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import * as appTheme from "./theme.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer, FormMode} from "@vidazoo/ui-framework";
import {
	Surface,
	Label,
	Form,
	Button,
	PageContainer,
	Loader,
	PageMessage,
	Modal,
	ConfirmPopMessage,
	SliderPanel,
	TabsPanel,
	Tab, Tooltip, FormInput, tooltipFactory
} from "@vidazoo/ui";
import Breadcrumbs, {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "common/services";
import {AddItemButton} from "common/components";
import EditApplicationStore from "../../stores/EditApplicationStore";
import DetailsForm from "./DetailsForm";
import HistoryForm from "./HistoryForm";
import {refreshAllCodeMirrorEditors} from "../../../../common/utils";
import {IButtonProps} from "@vidazoo/ui/lib/components";
import InheritEnvForm from "./InheritEnvForm";

export interface IEditApplicationProps extends RouteComponentProps<{ id: string }> {

}

export interface IEditApplicationState {
	showDeleteMessage: boolean;
	showGenerateKeyMessage: boolean;
	showKeysModal: boolean;
}

const TooltipButton = tooltipFactory<IButtonProps>(Button, {
	position: "bottom"
});

class EditApplication extends BaseContainer<IEditApplicationProps, IEditApplicationState> {

	public static defaultProps: Partial<IEditApplicationProps> = {};

	private store: EditApplicationStore;

	constructor(props, context) {
		super(props, context);

		this.store = new EditApplicationStore();

		this.setInitialState({
			showDeleteMessage: false,
			showGenerateKeyMessage: false,
			showKeysModal: false,
		});
	}

	public componentWillMount() {
		const {match} = this.props;

		this.store.getItem(match.params.id);
	}

	public componentDidMount() {
		this.store.getApplications();
	}

	private getTitle(): string {
		return this.store.isLoading
			? "Loading..."
			: this.store.loadError
				? "Error"
				: this.store.item.name;
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Applications",
			iconName: "icon-scenarios",
			link: {to: appUrlsService.applications()}
		}, {
			label: "update"
		}, {
			label: this.getTitle(),
			primary: true
		}];
	}

	private getPageTitle() {
		const title = ["Edit Application", this.getTitle()];

		return title;
	}

	private onSubmit = () => {
		this.store.submit();
	}

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Application"
					description="Please wait..."
				/>
			</div>
		);
	}

	private onCancelClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.applications());
	}

	private hideDeleteMessage = () => {
		this.setState({
			showDeleteMessage: false
		});
	}

	private showDeleteMessage = () => {
		this.setState({
			showDeleteMessage: true
		});
	}

	private hideGenerateKeyMessage = () => {
		this.setState({
			showGenerateKeyMessage: false
		});
	}

	private showGenerateKeyMessage = () => {
		this.setState({
			showGenerateKeyMessage: true
		});
	}

	private hideKeysModal = () => {
		this.setState({
			showKeysModal: false
		});
	}

	private showKeysModal = () => {
		this.setState({
			showKeysModal: true
		});
	}

	private delete = () => {
		this.hideDeleteMessage();

		this.store.delete();
	}

	private generateKey = () => {
		this.hideGenerateKeyMessage();

		this.store.generateKey();
	}

	private renderDeleteMessage(): JSX.Element {
		const {showDeleteMessage} = this.state;

		return (
			<Modal external exitOnClickOutside onClose={this.hideDeleteMessage} isOpen={showDeleteMessage}>
				<ConfirmPopMessage
					red
					title="Delete App?"
					text={<span>Are you sure you want to delete <Label weight={600}
																		block={false}>{this.store.item.name}</Label>?</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Delete"
					onCancelClick={this.hideDeleteMessage}
					onConfirmClick={this.delete}
				/>
			</Modal>
		);
	}

	private renderGenerateKeyMessage(): JSX.Element {
		const {showGenerateKeyMessage} = this.state;

		return (
			<Modal external exitOnClickOutside onClose={this.hideGenerateKeyMessage} isOpen={showGenerateKeyMessage}>
				<ConfirmPopMessage
					title="Generate New Key"
					text={<span>Are you sure you want to generate new key to <Label weight={600}
																					block={false}>{this.store.item.name}</Label>?</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Generate"
					onCancelClick={this.hideGenerateKeyMessage}
					onConfirmClick={this.generateKey}
				/>
			</Modal>
		);
	}

	private copyText = (e) => {
		e.target.select();
		document.execCommand("Copy");
		this.store.onCopySuccess();
	}

	private renderForm() {
		const {item} = this.store;

		return (
			<Form className={theme.form} onSubmit={this.onSubmit}>
				<TabsPanel onAnimationEnd={refreshAllCodeMirrorEditors} animate
							selectedIndex={this.state.selectedIndex} onChange={this.onTabChange}
							className={theme.midContent}>
					<Tab label="Details" hash="details">
						<Surface className={theme.surface} level={1}>
							<DetailsForm mode={FormMode.Edit} store={this.store}/>
						</Surface>
					</Tab>
					{item.inheritEnv ?
						<Tab label="Inherit Env" hash="Inherit-env">
							<Surface className={theme.surface} level={1}>
								<InheritEnvForm store={this.store}/>
							</Surface>
						</Tab>
						: null}
					<Tab label="History" hash="history">
						<Surface className={theme.surface} level={1}>
							<HistoryForm mode={FormMode.Edit} store={this.store}/>
						</Surface>
					</Tab>
				</TabsPanel>
				<SliderPanel>
					<div className={theme.actions}>
						<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
						<Button type="button" medium red uppercase onClick={this.showDeleteMessage}>Delete</Button>
						<AddItemButton iconName="icon-settings" label="Generate New Key" medium gray60 uppercase
										onClick={this.showGenerateKeyMessage}/>
						<Button type="submit" medium blue uppercase>Update</Button>
					</div>
				</SliderPanel>
			</Form>
		);
	}

	public renderKeysModal(): JSX.Element {

		const {appId, privateKey, isModalOpened, onHideModal} = this.store;

		return (
			<Modal key="1" isOpen={isModalOpened} exitOnClickOutside={false} panel title="Application keys"
					onClose={onHideModal} windowStyle={{width: "85rem", height: "60rem"}}>
				<Form className={appTheme.keys} key="1">
					<Tooltip title="double click to copy text" position="left">
						<FormInput onDoubleClick={this.copyText} readOnly className={appTheme.key} rows={7} label="APP Id"
									name="id" fill value={appId}/>
					</Tooltip>
					<Tooltip title="double click to copy text" position="left">
						<FormInput onDoubleClick={this.copyText} readOnly multiline className={appTheme.key} rows={16}
									label="private Key" name="privateKey" fill value={privateKey}/>
					</Tooltip>
					<div className="row align-center">
						<TooltipButton title="Copy private key before close!" className={appTheme.button} medium blue
										onClick={onHideModal}>Close</TooltipButton>
					</div>
				</Form>
			</Modal>
		)
	}

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="Failed to load example"
					description="We had an error loading the requested example."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		const {match} = this.props;

		return (
			<PageContainer pageTitle={this.getPageTitle()} inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()}/>
				{this.store.isLoading ? this.renderLoader() : null}
				{this.store.loadError ? this.renderError() : null}
				{!this.store.isLoading && !this.store.loadError ? this.renderForm() : null}
				{this.renderDeleteMessage()}
				{this.renderGenerateKeyMessage()}
				{this.renderKeysModal()}
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(EditApplication));
