import { observable, action, transaction } from "mobx";

export default class UIStore {
	@observable public isProfileModalOpen: boolean;

	constructor() {
		this.reset();

		this.toggleProfileModal = this.toggleProfileModal.bind(this);
	}

	@action
	public reset() {
		transaction(() => {
			this.isProfileModalOpen = false;
		});
	}

	@action
	public toggleProfileModal() {
		this.isProfileModalOpen = !this.isProfileModalOpen;
	}
}
