import * as React from "react";
import * as PropTypes from "prop-types";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import { AuthRoute } from "common/components";
import { appUrlsService } from "common/services";
import CreateApplication from "./CreateApplication";
import EditApplication from "./EditApplication";

export default (): JSX.Element => (
	<Switch>
		<AuthRoute path={appUrlsService.createApplication()} exact component={CreateApplication} />
		<AuthRoute path={appUrlsService.editApplication()} exact component={EditApplication} />
		<Redirect to={appUrlsService.createApplication()} />
	</Switch>
);
