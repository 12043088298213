import * as React from "react";
import {Icon, HLine, Button, Surface, Tooltip} from "@vidazoo/ui";
import {DayField, fastClone} from "@vidazoo/ui-framework";
import * as theme from "./themeItem.scss";
import {Link} from "react-router-dom";
import IApplication from "../../applications/interfaces/IApplication";
import {appUrlsService} from "common/services";
import IconsBar from "./IconsBar";

export interface IDashboardItemProps {
	item: IApplication;
	onRun: (item: IApplication) => void;
	onShowInfo: (item: IApplication) => void;
	onCopySuccess: () => void;
}

export interface IDashboardItemState {

}

export default class DashboardItem extends React.Component<IDashboardItemProps, IDashboardItemState> {
	public static defaultProps: Partial<IDashboardItemProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	public render(): JSX.Element {
		const {item, onCopySuccess} = this.props;

		return (
			<div className={theme.item}>
				<div className={theme.title}>
					<Link to={appUrlsService.editApplication(item.id)}>{item.name}</Link>
				</div>
				<div className={theme.box} key={item.id}>
					<div className="align-center">
						<div className="flex-2">
							<IconsBar item={item} onCopySuccess={onCopySuccess}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
