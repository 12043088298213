import Root from "./Root";
import { inject } from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
	isAuthenticating: stores.sessionStore.isAuthenticating,
	isAuthError: stores.sessionStore.isAuthError,
	isAuthTimeout: stores.sessionStore.isAuthTimeout,
	history: stores.navigationStore.history,
	pendingNewVersion: stores.initialState.pendingNewVersion,
	setPendingNewVersionState: stores.initialState.setPendingNewVersion
}))(Root);
