import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { BaseContainer } from "@vidazoo/ui-framework";
import { Surface, Form, Button, PageContainer, SliderPanel, Tab, TabsPanel } from "@vidazoo/ui";
import { FormMode } from "@vidazoo/ui-framework";
import Breadcrumbs, { IBreadcrumb } from "@vidazoo/ui/lib/components/breadcrumbs";
import { appUrlsService } from "common/services";
import CreateApplicationStore from "../../stores/CreateApplicationStore";
import DetailsForm from "./DetailsForm";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import { Entity } from "common/enums";
import ApplicationContext from "./ApplicationContext";
import ApplicationsStore from "../../../applications/stores/ApplicationsStore";

export interface ICreateApplicationProps extends RouteComponentProps<{}> {

}

export interface ICreateApplicationState {

}

class CreateApplication extends BaseContainer<ICreateApplicationProps, ICreateApplicationState> {

	public static defaultProps: Partial<ICreateApplicationProps> = {};

	private store: CreateApplicationStore;
	private applicationsStore: ApplicationsStore;

	constructor(props, context) {
		super(props, context);
		this.applicationsStore = new ApplicationsStore();
		this.store = new CreateApplicationStore();
	}

	public componentDidMount() {
		this.store.getApplications();
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Applications",
			iconName: "icon-scenarios",
			link: { to: appUrlsService.applications() }
		}, {
			label: "Create Application",
			primary: true
		}];
	}

	private onSubmit = () => {
		this.store.submit();
	}

	private onCancelClick = () => {
		const { history } = this.props;

		history.push(appUrlsService.applications());
	}

	public render(): JSX.Element {
		return (
			<PageContainer pageTitle="Create Example" inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()} />
				<Form className={theme.form} onSubmit={this.onSubmit}>
						<TabsPanel animate selectedIndex={this.state.selectedIndex} onChange={this.onTabChange} className={theme.midContent}>
							<Tab label="Details" hash="details">
								<Surface className={theme.surface} level={1}>
									<DetailsForm mode={FormMode.Create} store={this.store}/>
								</Surface>
							</Tab>
						</TabsPanel>
					<SliderPanel>
						<div className={theme.actions}>
							<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
							<Button type="submit" medium blue uppercase>Create</Button>
						</div>
					</SliderPanel>
				</Form>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(CreateApplication));
