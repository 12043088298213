import { BaseStorageService, IReportingEntry, IReportingFilter, IReportingConstraint } from "@vidazoo/ui-framework";

class StorageService extends BaseStorageService {

	public getReportingEntries = (entry: "groups" | "fields"): IReportingEntry[] => {
		return this.get(`reporting.${entry}`, null);
	}

	public setReportingEntries = (entry: "groups" | "fields", values: IReportingEntry[]) => {
		this.set(`reporting.${entry}`, values);
	}

	public getReportingFilters = (): IReportingFilter[] => {
		return this.get(`reporting.filters`, null);
	}

	public setReportingFilters = (filters: IReportingFilter[]) => {
		this.set(`reporting.filters`, filters);
	}

	public getReportingConstraints = (): IReportingConstraint[] => {
		return this.get(`reporting.constraints`, null);
	}

	public setReportingConstraints = (filters: IReportingConstraint[]) => {
		this.set(`reporting.constraints`, filters);
	}

	public getGlobalTimezone = (): string => {
		return this.get(`global.timezone`, "");
	}

	public setGlobalTimezone = (timezone: string) => {
		this.set(`global.timezone`, timezone);
	}
}

export default new StorageService();
