import * as React from "react";
import * as PropTypes from "prop-types";
import * as classnames from "classnames";
import * as _ from "lodash";
import * as theme from "./theme.scss";
import IAccount from "modules/accounts/interfaces/IAccount";
import { Checkbox, Modal, Label, Button, LinkButton, FormSelect, Form } from "@vidazoo/ui";
import { FormMultiSelect, MultiSelect, MultiSelectGroup, MultiSelectOption } from "@vidazoo/ui/lib/components/multiSelect";

export interface IAccountsListProps {

	accounts?: IAccount[];

	isMultiAccounts?: boolean;

	selectedAccounts?: string[];

	setSelectedAccounts?: (accounts: string[], reload?: boolean) => void;

	indexAccounts?: any;
}

export interface IAccountsListState {
	isOpen: boolean;

	userSelectedAccounts: string[];
}

export default class AccountsList extends React.Component<IAccountsListProps, IAccountsListState> {

	constructor(props, context) {
		super(props, context);

		this.state = { isOpen: false, userSelectedAccounts: [] };
	}

	private toggleModal = () => {

		const nextState: IAccountsListState = {
			isOpen: !this.state.isOpen,
			userSelectedAccounts: []
		};

		if (nextState.isOpen) {
			nextState.userSelectedAccounts = this.props.selectedAccounts;
		}

		this.setState(nextState);
	}

	private setAccounts = () => {
		const { setSelectedAccounts } = this.props;

		const { userSelectedAccounts } = this.state;

		if (userSelectedAccounts.length) {
			setSelectedAccounts(userSelectedAccounts, true);
		}

		this.toggleModal();
	}

	private onSelectAccounts = (accounts: string[]) => {
		this.setState({
			userSelectedAccounts: accounts
		});
	}

	private renderNetworkAccounts() {
		const { accounts } = this.props;

		const groups: any = {};

		accounts.forEach((account) => {
			const network = account.network as any;

			if (groups[network._id]) {
				groups[network._id].accounts.push(account);
			} else {
				groups[network._id] = {
					network,
					accounts: [account]
				};
			}
		});

		const props: any = {
			onSelectionChange: this.onSelectAccounts,
			value: this.state.userSelectedAccounts,
			label: "Select accounts",
			surfaceLevel: 0,
			maxItems: 5,
			allowOnlySameGroupSelection: true,
			searchable: true,
			multiple: true,
			required: true
		};

		const render = (
			<div style={{ marginBottom: "2rem", marginTop: "2rem" }}>
				<FormMultiSelect {...props}>
					{_.map(groups, (group: any) => {
						return (
							<MultiSelectGroup label={group.network.name} value={group.network._id} key={group.network._id}>
								{_.map(group.accounts, (account: any) => {
									return (
										<MultiSelectOption label={account.username} value={account._id} key={account._id} />
									);
								})}
							</MultiSelectGroup>
						);
					})}
				</FormMultiSelect>
			</div>
		);

		return render;
	}

	private renderAccounts(): JSX.Element {
		const { selectedAccounts, accounts, indexAccounts } = this.props;
		const { isOpen, userSelectedAccounts } = this.state;

		const selected = selectedAccounts.map((account) => indexAccounts[account].username);

		return (
			<div className={theme.accounts}>
				<div className="flex">
					<Label className={theme.label} uppercase={false} size={13}>Accounts: {selected.length > 5 ? _.take(selected, 5).join(", ") + "..." : selected.join(", ")}</Label>
					<LinkButton className={theme.icon} onClick={this.toggleModal} iconName="icon-plus-small" iconSize={1.8}></LinkButton>
				</div>
				<Modal onClose={this.toggleModal} panel isOpen={isOpen} title="Change Active Accounts" scrollableContent={false} windowStyle={{ minWidth: 450 }}>
					<Form onSubmit={this.setAccounts}>
						{isOpen ? this.renderNetworkAccounts() : null}
						<div className={theme.actions}>
							<Button type="button" gray medium uppercase onClick={this.toggleModal}>Cancel</Button>
							<Button type="submit" blue medium uppercase >Set Accounts</Button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}

	public render(): JSX.Element {
		const { isMultiAccounts } = this.props;

		return (
			isMultiAccounts ? this.renderAccounts() : null
		);
	}
}
