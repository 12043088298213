import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {observer} from "mobx-react";
import {CodeEditor, guid} from "@vidazoo/ui-framework";
import EditApplicationStore from "../../stores/EditApplicationStore";

export interface IInheritEnvFormProps {

	store: EditApplicationStore;

}

export interface IInheritEnvFormState {

}

@observer
export default class InheritEnvForm extends React.Component<IInheritEnvFormProps, IInheritEnvFormState> {

	public static defaultProps: Partial<IInheritEnvFormProps> = {};

	constructor(props: IInheritEnvFormProps, context) {
		super(props, context);
	}

	private renderConfigsEditor = (inheritEnv): JSX.Element => {

		return (
			<CodeEditor
				key={guid()}
				style={{width: "100%"}}
				label="Inherit Env"
				value={inheritEnv}
				options={{readOnly: true, mode: "javascript"}}
			/>
		);
	}

	public render(): JSX.Element {

		const {store} = this.props;

		const {inheritEnv} = store;

		return (
			<div className={theme.form}>
					<div className={theme.row}>
						{this.renderConfigsEditor(inheritEnv)}
					</div>
			</div>
		);
	}
}
