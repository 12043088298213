import {action, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import applicationsAPI from "../api/applicationsAPI";
import {notificationsStore} from "common/stores";
import IApplication from "../interfaces/IApplication";
import * as _ from "lodash";

const LIST_SEARCHABLE_FIELDS = ["name"];
const LIST_FIELDS = {name: 1};

export default class ApplicationsStore extends BaseListStore<IApplication> {

	constructor() {
		super(notificationsStore, LIST_SEARCHABLE_FIELDS, "applications");
	}

	@action
	public reset() {
		transaction(() => {
			super.reset();
		});
	}

	@action public getItems = () => {
		if (this.isLoading) {
			return;
		}

		transaction(() => {
			this.isLoading = true;
			this.selectedValues = [];
			this.resetSearchQueries();
			this.setPromptDeleteItem(null);
		});

		const sort = {
			[this.sortBy]: this.sortDir
		};

		applicationsAPI.getAll({fields: LIST_FIELDS})
			.then((res) => {
				res.data.results = _.sortBy(res.data.results, ["name"]);
				this.onLoadItemsSuccess(res);
			})
			.catch((res) => this.onLoadItemsError(res));
	}

	@action public toggleActiveState = (item: IApplication) => {
		item.isActive = !item.isActive;

		applicationsAPI.active(item._id, item.isActive)
			.then(() => this.onUpdateStateSuccess())
			.catch(() => this.onUpdateStateError(item));
	}

	@action public deleteItem = () => {
		applicationsAPI.delete(this.promptDeleteItem._id)
			.then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
			.catch(() => this.onDeleteItemError());
	}

	protected deleteSelectedValues() {
		return Promise.all(this.selectedValues.map((x) => applicationsAPI.delete(x)));
	}

	protected setActiveSelectedValues(state: boolean) {
		return Promise.all(this.selectedValues.map((x) => applicationsAPI.active(x, state)));
	}
}
