import { action } from "mobx";
import { navigationStore } from "common/stores";
import { appUrlsService } from "common/services";
import applicationsAPI from "../api/applicationsAPI";
import BaseApplicationStore from "./BaseApplicationStore";

export default class CreateApplicationStore extends BaseApplicationStore {

	@action public submit() {
		const item = this.setAccounts();

		applicationsAPI.create(item)
			.then(() => navigationStore.push(appUrlsService.applications()))
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}
}
