import * as React from "react";
import * as PropTypes from "prop-types";
import { TableCell } from "@vidazoo/ui";

export interface IMultiAccountCellProps {
	sortBy?: string;
	item: any;
	isMultiSelectedAccounts?: boolean;
}

export default ({ isMultiSelectedAccounts, item, sortBy }: IMultiAccountCellProps): JSX.Element =>
	isMultiSelectedAccounts ? <TableCell highlight={sortBy === "user"}>{item.user.username}</TableCell> : null;
