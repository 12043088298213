import * as React from "react";
import * as PropTypes from "prop-types";
import { TableHeaderColumn } from "@vidazoo/ui";
import IDictionary from "@vidazoo/ui-framework/lib/interfaces/IDictionary";
import SortDirection from "@vidazoo/ui/lib/common/SortDirection";

export interface IMultiAccountHeaderProps {
	sortBy?: string;
	isMultiSelectedAccounts?: boolean;
	searchQueries?: IDictionary<string>;
	sortDirection?: SortDirection;
	onSort?: (sortBy: string) => void;
	onSearch?: (searchKey: string, value: string) => void;
}

export default ({ isMultiSelectedAccounts, sortBy, onSort, sortDirection, onSearch, searchQueries }: IMultiAccountHeaderProps): JSX.Element =>
	isMultiSelectedAccounts ? <TableHeaderColumn
		blue
		label="Account"
		sortable={true}
		sortBy="user.username"
		onSort={onSort}
		sortActive={sortBy === "user.username"}
		sortDirection={sortDirection}
		searchKey="user.username"
		searchable={true}
		onSearch={onSearch}
		searchQuery={searchQueries["user.username"]}
	/> : null;
