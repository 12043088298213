import * as React from "react";
import * as PropTypes from "prop-types";
import * as moment from "moment";
import * as theme from "styles/_baseList.scss";
import { observer } from "mobx-react";
import { TableCell, Switch, LinkButton, TableCellToggle } from "@vidazoo/ui";
import { MultiAccountCell } from "common/components";
import { Link } from "react-router-dom";
import { appUrlsService } from "common/services";
import IApplication from "../../interfaces/IApplication";

export interface IExampleRowProps {

	item: IApplication;

	sortBy: string;

	onActiveChange: (item: IApplication) => void;

	onEdit: (item: IApplication) => void;

	onDelete: (item: IApplication) => void;

	onToggle: (id: string) => void;

	isSelected: boolean;
}

export interface IExampleRowState {

}

@observer
export default class ApplicationRow extends React.Component<IExampleRowProps, IExampleRowState> {

	public static defaultProps: Partial<IExampleRowProps> = {

	};

	constructor(props, context) {
		super(props, context);
	}

	private onActiveChange = () => {
		const { onActiveChange, item } = this.props;

		onActiveChange(item);
	}

	private onDelete = () => {
		const { onDelete, item } = this.props;

		onDelete(item);
	}

	private onEdit = () => {
		const { item, onEdit } = this.props;

		onEdit(item);
	}

	private onToggle = () => {
		const { item, onToggle } = this.props;

		onToggle(item._id);
	}

	public render(): JSX.Element {

		const { item, sortBy, isSelected } = this.props;

		return (
			<tr>
				<TableCellToggle checked={isSelected} onChange={this.onToggle} />
				<TableCell highlight={sortBy === "name"}>
					<Link to={appUrlsService.editApplication(item.id)}>{item.name}</Link>
				</TableCell>
				<TableCell highlight={sortBy === "id"}>{item.id}</TableCell>
				<MultiAccountCell item={item} sortBy={sortBy} />
				{/*<TableCell highlight={sortBy === "created"}>{moment(item.created).format("MMM DD, YYYY")}</TableCell>*/}
				{/*<TableCell highlight={sortBy === "updated"}>{moment(item.updated).format("MMM DD, YYYY")}</TableCell>*/}
				{/*<TableCell highlight={sortBy === "isActive"}>*/}
				{/*	<Switch small checked={item.isActive} onChange={this.onActiveChange} />*/}
				{/*</TableCell>*/}
				<TableCell maxWidth={200}>
					<div className={theme.links}>
						<LinkButton onClick={this.onEdit} iconName="icon-edit" iconSize={1.8}></LinkButton>
						<LinkButton onClick={this.onDelete} iconName="icon-delete" iconSize={1.8}></LinkButton>
					</div>
				</TableCell>
			</tr>
		);
	}
}
