import * as React from "react";
import classnames from "classnames";
import * as theme from "./theme.scss";
import { RouteComponentProps } from "react-router";
import { UserScope } from "common/components";
import { AppBar } from "@vidazoo/ui/lib/components/appBar";
import { UserMenu } from "@vidazoo/ui/lib/components/userMenu";
import { MainMenu } from "@vidazoo/ui/lib/components/mainMenu";
import {MenuItem } from "@vidazoo/ui/lib/components/menu";
import { MenuTree, IMenuNode } from "@vidazoo/ui/lib/components/menuTree";
import { UserRole, ThemeSwitch, VidazooApps } from "@vidazoo/ui-framework";
import { appUrlsService } from "common/services";
import AccountsList from "../accountsList";
import routes from "common/routes";
import settingsRoute from "common/settingsRoute";
import { NetworkFeatures } from "common/enums";
import {VidazooHeaderLogo} from "@vidazoo/ui";

const USER_MENU_LINKS = [
	{ label: "Terms & Conditions", native: true, linkProps: { href: (window as any).__TERMS_URL__, target: "_blank" } },
	{ label: "Privacy Policy", native: true, linkProps: { href: (window as any).__PRIVACY_URL__, target: "_blank" } }
];

export interface IAppHeaderProps extends RouteComponentProps<any> {
	userName?: string;

	userEmail?: string;

	userScopes?: string[];

	isInRoles?: (rols: UserRole[]) => boolean;

	isFeatureAllowed?: (include?: NetworkFeatures[] | NetworkFeatures, exclude?: NetworkFeatures[] | NetworkFeatures) => boolean;

	userAvatarUrl?: string;

	onSignOut?: () => void;

	openMyProfile?: () => void;
}

export interface IAppHeaderState {
	mounted: boolean;
}

export default class AppHeader extends React.Component<IAppHeaderProps, IAppHeaderState> {

	public static defaultProps: Partial<IAppHeaderProps> = {

	};

	constructor(props, context) {
		super(props, context);

		this.state = { mounted: false };
	}

	public componentDidMount() {
		window.requestAnimationFrame(() => {
			this.setState({ mounted: true });
		});
	}

	public onLogoClick = () => {
		const { history } = this.props;

		history.push(appUrlsService.applications());
	}

	private onLogout = () => {
		const { onSignOut } = this.props;

		onSignOut();
	}

	private onAddApp = () => {
		const { history } = this.props;

		history.push(appUrlsService.createApplication());
	}

	private reduceRouteItems(items: IMenuNode[]): IMenuNode[] {
		const { isInRoles, isFeatureAllowed } = this.props;

		return items.reduce((result, item) => {
			if (isInRoles(item.access) && isFeatureAllowed(item.includeFeatures, item.excludeFeatures)) {
				if (item.items) {
					item.items = this.reduceRouteItems(item.items);
				}

				return result.concat(item);
			}

			return result;
		}, []);
	}

	public renderSettingsMenu(): JSX.Element {
		return (
			<UserScope scope={settingsRoute.access}>
				<MainMenu iconName={settingsRoute.iconName} stickTo="right">
					{settingsRoute.items ? settingsRoute.items.map((item) => {
						return (
							<UserScope scope={item.access} key={item.title}>
								<MenuItem label={item.title} link={item.link} />
							</UserScope>
						);
					}) : null}
				</MainMenu>
			</UserScope>
		);
	}

	public render(): JSX.Element {

		const { location, userName, userEmail, userAvatarUrl, openMyProfile, userScopes } = this.props;

		const { mounted } = this.state;

		const classes = classnames(theme.header, {
			[theme.mounted]: mounted
		});

		return (
			<AppBar enableSticky className={classes}>
				<div className={theme.inner}>
					<div className="flex align-center">
						<div onClick={this.onLogoClick} className={theme.logo}>
							<VidazooHeaderLogo width={15.6} color={"#848bff"} logoName={"Vault"}/>
						</div>
					</div>
					<MenuTree className="flex-1" items={this.reduceRouteItems(routes)} />
					<ThemeSwitch className={theme["theme-switch"]} />
					<AccountsList />
					<div className="flex align-center">
						{<VidazooApps userScopes={userScopes} currentScope={window.VidazooSDK.Scope.VAULT} />}
						<UserMenu links={USER_MENU_LINKS} smallAvatar darkAvatar userName={userName} userEmail={userEmail} avatarUrl={userAvatarUrl} className={theme.user}>
							<MenuItem iconName="icon-settings" label="My Profile" onClick={openMyProfile} />
							<MenuItem iconName="icon-sign-out" label="Sign Out" onClick={this.onLogout} />
						</UserMenu>
					</div>
				</div>
			</AppBar>
		);
	}
}
