import {action, observable, transaction} from "mobx";

export default class SessionStore {
	@observable public isAuthenticated: boolean = false;
	@observable public isAuthenticating: boolean = true;
	@observable public isAuthError: boolean = false;
	@observable public accessToken: string;
	public user: any;
	public userScopes: any[];

	constructor() {
		(window as any).__sdk__.auth.on("session:change", (err, res) => this.onSessionChange(err, res));

		this.isAuthenticated = false;
		this.isAuthenticating = true;
		this.isAuthError = false;
		this.accessToken = null;
	}

	@action
	private async onSessionChange(err, response) {
		if (response) {
			this.accessToken = response.accessToken;
			this.userScopes = response.user.scopes;
		}

		try {
			this.onLogin(response.user);
		} catch (e) {
			this.onLoginFailed(e);
		}
	}

	@action
	private onLogin(user) {
		transaction(() => {
			this.user = user;
			this.isAuthenticated = true;
			this.isAuthenticating = false;
			this.isAuthError = false;
		});
	}

	@action
	private onLoginFailed(err) {
		transaction(() => {
			this.accessToken = null;
			this.isAuthenticated = false;
			this.isAuthenticating = false;
			this.isAuthError = true;
		});
	}

	@action
	public initialize() {
		(window as any).__sdk__.auth.authorize();
	}

	@action public logout = () => {
		(window as any).__sdk__.auth.logout();
	}

	@action public isInRoles = () => {
		return true;
	}
}
