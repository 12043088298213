(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"), require("ReactDOM"), require("moment"), require("setimmediate"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "_", "ReactDOM", "moment", "setimmediate"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("React"), require("_"), require("ReactDOM"), require("moment"), require("setimmediate")) : factory(root["React"], root["_"], root["ReactDOM"], root["moment"], root["setimmediate"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__31__, __WEBPACK_EXTERNAL_MODULE__260__) {
return 