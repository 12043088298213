import IMenuEntry from "common/interfaces/IMenuEntry";
import { appUrlsService } from "common/services";
import { DEFAULT_ACCESS } from "./enums";

export default [
	{
		title: "Dashboard",
		iconName: "icon-dashboard",
		link: { to: appUrlsService.dashboard() },
	},
	{
		title: "Applications",
		iconName: "icon-platform",
		link: { to: appUrlsService.applications() },
	},
] as IMenuEntry[];
