import { BaseAPI } from "@vidazoo/ui-framework";
import { IGetAll } from "../../../common/interfaces/IGetAll";

class ApplicationsAPI extends BaseAPI {
	public getAll({ page = null, pageSize = null, filter = {}, fields = {}, sort = {} }: IGetAll) {
		return this.request({
			method: "get",
			url: "/api/configs/",
			params: { page, pageSize, filter, fields, sort }
		});
	}

	public getAllDashboard({ page = null, pageSize = null, filter = {}, fields = {}, sort = {} }: IGetAll) {
		return this.request({
			method: "get",
			url: "/api/configs/dashboard",
			params: { page, pageSize, filter, fields, sort }
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/configs/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/configs/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/configs/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/configs/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/configs/${id}/active/`,
			data: { isActive }
		});
	}

	public generateKey(id: string) {
		return this.request({
			method: "patch",
			url: `/api/configs/generateKey/${id}/`
		});
	}

	public restore(id: string, version) {
		return this.request({
			method: "patch",
			url: `/api/configs/restore/${id}/`,
			data: { version }
		});
	}
}

export default new ApplicationsAPI(window.__BASE_API_URL__);
