import {UserRole} from "@vidazoo/ui-framework";

export enum Entity {
	Example = "example",
}

export enum NetworkFeatures {
	VIDAZOO = "vidazoo",
}

export const DEFAULT_ACCESS = [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN];

export const dateFields = ["created", "updated", "date", "from", "to"];
