import AccountsList from "./AccountsList";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
	accounts: stores.sessionStore.userAccounts,
	isMultiAccounts: stores.sessionStore.isMultiAccounts,
	selectedAccounts: toJS(stores.sessionStore.selectedAccounts),
	setSelectedAccounts: stores.sessionStore.setSelectedAccounts,
	indexAccounts: stores.sessionStore.indexAccounts
}))(observer(AccountsList));
